import './Components.css';
import logo from '../logo.svg';

const Logo = () => {

    // 各URL
    const url1 = "http://localhost:3000";    // テスト用
    //const url1 = "";                  // 本番用
    const imageUrl = "https://example.com/image.jpg";   //ロゴimg
    const altText = "Example Image";                    //ロゴalt

    return (
            <a className="logo-s" href={url1}>
                <img src={logo} alt={altText} />
            </a>
    )
}
export default Logo;