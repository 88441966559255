import './Components.css';
import Logo from './Logo';

const Navbar = () => {

    // 各URL
    const url1 = "http://localhost";    // テスト用
    //const url1 = "";                  // 本番用
    const imageUrl = "https://example.com/image.jpg";   //ロゴimg
    const altText = "Example Image";                    //ロゴalt

    return (
        <nav className="nav">
            <Logo />
            <ul>
                <li><a href="#">質問</a></li>
                <li><a href="#">ログイン</a></li>
                <li><a href="#">新規登録</a></li>
            </ul>
        </nav>
    )
}
export default Navbar;