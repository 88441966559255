import './Page.css';

import Navbar from '../components/ Navbar';

// Facebookログインのプロパティの型を定義（仮定）
function PrivacyPolicy() {
    return (
        <div>
            <Navbar />
            <div className='privacy-policy'>
                <h1>プライバシーポリシー</h1>
                <p>このプライバシーポリシーは、当社のウェブサイト及びアプリケーションを利用する際の個人情報の取り扱いについて説明しています。</p>

                <h2>1. 情報の収集</h2>
                <p>当社は、ユーザーが提供する情報や利用状況に関する情報を収集します。</p>

                <h2>2. 情報の利用</h2>
                <p>収集した情報は、サービスの提供・改善、カスタマーサポートの提供、セキュリティの維持のために利用されます。</p>

                <h2>3. クッキーの使用</h2>
                <p>当社のウェブサイトでは、ユーザー体験を向上させるためにクッキーを使用します。</p>

                <h2>4. 情報の第三者提供</h2>
                <p>当社は、法令に基づく場合を除き、ユーザーの同意なく第三者に個人情報を提供しません。</p>

                <h2>5. セキュリティ</h2>
                <p>当社は、ユーザーの個人情報を保護するために適切なセキュリティ対策を講じています。</p>

                <h2>6. ユーザーの権利</h2>
                <p>ユーザーは、個人情報の確認、修正、削除を求める権利があります。</p>

                <p>お問い合わせ先：inquiry@k-platinum.com</p>
            </div>
        </div>
    );
    }

export default PrivacyPolicy;