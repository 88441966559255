import React from 'react';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import Navbar from './components/ Navbar';

// Facebookログインのプロパティの型を定義（仮定）
function Login() {
    return (
        <div>
            <Navbar />
            <LoginSocialFacebook
                appId="927842925570812" // ここにFacebookアプリのIDを設定
                onResolve={(response) => {
                    console.log(response);
                }}
                onReject={(error) => {
                    console.log(error);
                }}
                >
                <FacebookLoginButton />
            </LoginSocialFacebook>
        </div>
    );
    }

export default Login;
